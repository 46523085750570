import React, { useState } from 'react';
import './css/UpdatePass.css'
import { Link } from 'react-router-dom';
import { IoIosArrowForward } from "react-icons/io";

const UpdatePass2 = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError('كلمات المرور غير متطابقة');
    } else {
      setError('');
      // هنا ستقوم بإرسال كلمة السر الجديدة إلى السيرفر لتحديثها
      console.log('New password:', password);
      alert('تم تحديث كلمة المرور بنجاح');
    }
  };

  return (
    <div className='UpdatePass'>
      <div className="CreatePost-header">
        <Link to={-1}>
          <h2><IoIosArrowForward /></h2>
        </Link>
        <h3>تعديل كلمة السر</h3>
        <Link to={'/'}>
          <img id='imglogo' src="./logo-elfanane.png" alt="Logo" />
        </Link>
      </div>
      <div className="UpdatePass-container">
        <h2>تحديث كلمة المرور</h2>
        <p>الرجاء إدخال كلمة المرور الجديدة وتأكيدها.</p>
        <form onSubmit={handleSubmit}>
          <label htmlFor="password">كلمة المرور الجديدة</label>
          <input 
            type="password" 
            id="password" 
            value={password} 
            onChange={handlePasswordChange} 
            placeholder="أدخل كلمة المرور الجديدة" 
            required 
          />
          <label htmlFor="confirmPassword">تأكيد كلمة المرور</label>
          <input 
            type="password" 
            id="confirmPassword" 
            value={confirmPassword} 
            onChange={handleConfirmPasswordChange} 
            placeholder="أكد كلمة المرور" 
            required 
          />
          {error && <p className="error-message">{error}</p>}
          <button type="submit">تحديث كلمة السر</button>
        </form>
      </div>
    </div>
  );
};

export default UpdatePass2;
