import React, { useState } from 'react';
import './css/UpdatePass.css'
import { Link, useNavigate } from 'react-router-dom';
import { IoIosArrowForward } from "react-icons/io";

const UpdatePass = () => {
  const [email, setEmail] = useState('');

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // هنا ستقوم بإرسال البريد الإلكتروني إلى السيرفر ليرسل رابط التحقق
    console.log('Email for password reset:', email);
    alert(`تم إرسال رابط التحقق إلى ${email}`);
  };

  return (
    <div className='UpdatePass'>
        <div className="CreatePost-header">
              <Link to={-1}>
              <h2><IoIosArrowForward /></h2>
              </Link>
                <h3>نسيت كلمة المرور؟</h3>
                <Link to={'/'}>
                <img id='imglogo' src="./logo-elfanane.png" alt="" />
                </Link>
            </div>
      <div className="UpdatePass-container">
        <h2>نسيت كلمة المرور؟</h2>
        <p>الرجاء إدخال بريدك الإلكتروني لنرسل لك رابط إعادة تعيين كلمة المرور.</p>
        <form onSubmit={handleSubmit}>
          <label htmlFor="email">البريد الإلكتروني</label>
          <input 
            type="email" 
            id="email" 
            value={email} 
            onChange={handleEmailChange} 
            placeholder="أدخل بريدك الإلكتروني" 
            required 
          />
          <button type="submit">إرسال رابط التحقق</button>
        </form>
      </div>
    </div>
  );
};

export default UpdatePass;